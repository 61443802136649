
  import userController from '/home/builduser/agent00/work/d3ce2d6334d0e6ab/client/wixstores-client/wixstores-client-gallery/src/components/GridGallery/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/controllerWrapper.js';

  
const wrapController = null;


  
var createHttpClient = null;

  
var initI18n = null;

  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = null;

  var experimentsConfig = {"scopes":["viewer-apps-1380b703-ce81-ff05-f115-39571d94dfcd"],"centralized":true};

  var translationsConfig = {"enabled":false,"icuEnabled":false};

  var biConfig = null;

  var defaultTranslations = null;

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/agent00/work/d3ce2d6334d0e6ab/client/wixstores-client/wixstores-client-gallery/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: null,
    componentName: "GridGallery",
    appDefinitionId: "1380b703-ce81-ff05-f115-39571d94dfcd",
    componentId: "13afb094-84f9-739f-44fd-78d036adb028",
    projectName: "wixstores-client-gallery",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/agent00/work/d3ce2d6334d0e6ab/client/wixstores-client/wixstores-client-gallery/src/components/GridGallery/controller.ts",
  };

  export const controller = _controller
  export default controller;
